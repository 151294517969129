
import { defineComponent, ref, watch } from 'vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import NewsBox from '../../components/Frontend/NewsBox.vue';
import Footer from '../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export type SearchRecord = {
  locale: string;
  title: string;
  meta: string;
  content: string;
  url: string;
  description: string;
  createdat: Date;
};

export default defineComponent({
  meta: {
    de: {
      title: 'Suche | Styria Media Group',
      keywords: ['suche','search','suchen nach','styria media group'],
      description: 'Wonach suchen Sie?',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'Search | Styria Media Group',
      keywords: ['search','graz','styria media group'],
      description: 'What are you looking for?',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    NewsBox,
    Footer,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const filter = ref('');

    let timeout: number | undefined;

    const results = ref<Array<SearchRecord>>([]);
    const loading = ref(false);

    watch(filter, (newvalue, _) => {
      loading.value = true;
      if (newvalue == '') {
        clearTimeout(timeout);
        results.value = [];
        loading.value = false;
      } else {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          store.dispatch('search', { filter: filter.value, locale: i18n.locale.value }).then((res: any) => {
            results.value = res;
            loading.value = false;
          });
        }, 100);
      }
    });

    return {
      results,
      mdiChevronUp,
      filter,
      loading,
    };
  },
});
