<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .suche-all {
    .search-bar-outer {
      background: #ebebeb;
      padding: 20px;
      position: relative;
      top: -42px;
      .input-group {
        input {
          border-radius: 0;
          outline: none;
          padding: 15px 20px;
          border: 1px solid #fff;
          &:focus,
          &:active {
            box-shadow: none;
            border: 1px solid $violet;
          }
        }
        .input-group-text {
          background: #fff;
          position: relative;
          left: -1px;
          border: 1px solid #fff;
          border-left: 0;
          padding: 0.375rem 1rem;
          img {
            width: 30px;
          }
        }
        ::-webkit-input-placeholder {
          /* Edge */
          letter-spacing: 2px;
          color: #a0a0a0;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          letter-spacing: 2px;
          color: #a0a0a0;
        }
        ::placeholder {
          letter-spacing: 2px;
          color: #a0a0a0;
        }
      }
    }
  }
  .outer-proposals {
    .inner-prop {
      margin-top: 20px;
      border: 10px solid #ccc;
      padding: 30px;
      position: relative;
      transition: $transition-std;
      .txt {
        text-align: center;
        h2 {
          margin-top: 30px;
          font-family: 'American Typewriter ITC W04 Md';
          text-transform: none;
          font-size: 180%;
          padding: 0 40px;
        }
      }
      span {
        position: absolute;
        width: 100%;

        bottom: 30px;
        left: 0;
        display: block;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: $letter-spacing-std;
        @media (max-width: $width-md) {
          position: relative;
          margin-top: 60px;
          bottom: 0;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .height-500 {
      height: 500px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .height-400 {
      height: 400px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .height-300 {
      height: 300px;
      @media (max-width: $width-md) {
        height: auto;
      }
    }
    .b-coral {
      border-color: $coral;
      color: $coral;
    }
    .b-wine-red {
      border-color: $wine-red;
    }
    .b-blue-60per {
      border-color: $blue-60per;
      color: $coral;
    }
    .b-mint {
      border-color: $mint;
    }
    .b-violet {
      border-color: $violet-50per;
      background-color: $violet-50per;
      color: #fff;
    }
    .b-sand {
      border-color: $sand;
      background-color: $sand;
    }
  }

  .results {
    list-style: none;
    padding: 0;
    .result {
      cursor: pointer;
      padding: 15px;
      //border:1px solid #fff;
      display: block;
      margin: 0 auto;
      text-align:center;
      transition: all 0.5s ease;

      &:hover {
        background-color: $grey;
      }
      .resultcontainer {
        position: relative;
        letter-spacing:1px;
      }
      .title {
        font-family: 'Helvetica Neue LT W05 65 Medium';
      }
      .url {
        font-family: 'Helvetica Neue LT W05 56 Italic';
        font-size: 80%;

        .text-underline {
          text-decoration: underline;
        }
      }
    }

    //

    // .flip-list-enter-active,
    // .flip-list-leave-active {
    //   transition: opacity 0.2s ease;
    // }
    // .flip-list-enter-from,
    // .flip-list-leave-to {
    //   opacity: 0;
    //   position: absolute;
    // }
  }
}
</style>

<template>
  <div class="suche-all">
    <SocialMediaIconBox />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <h1>{{ $t('suche.suche') }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="search-bar-outer">
            <div class="input-group">
              <input type="text" class="form-control" :placeholder="$t('suche.ich-suche-nach')" v-model="filter" />
              <span class="input-group-text"><img src="@/assets/img/header-suche-icon-v2.svg" alt="Kontakt"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="loading">
      <!-- LOADING -->
      <div class="row justify-content-center">
        <div class="col-lg-10 text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <!-- RESULTS -->
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <ul class="results">
            <ul class="results">
              <li class="result" :key="result.url" v-for="result of results">
                <router-link :to="result.url" custom v-slot="{}">
                  <div class="resultcontainer">
                    <div class="title">
                      {{ result.title }}
                    </div>
                    <div class="url">
                      <span class="text-underline">{{ result.url }}</span>
                    </div>
                    <a :href="result.url" target="_blank" class="stretched-link"></a>
                  </div>
                </router-link>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>

    <div class="container" v-if="!loading && results.length <= 0 && filter != ''">
      <!-- SEARCH BUT NO RESULTS -->
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <p class="text-center">{{ $t('General.keine-ergebnisse') }}</p>
        </div>
      </div>
    </div>

    <div class="container outer-proposals margin-t-m">
      <!-- NO SEARCH NO RESULTS -->
      <div class="row align-self-end justify-content-center">
        <div class="col-lg-3 col-md-6 col-10 align-self-end">
          <div class="inner-prop height-300 b-coral">
            <div class="txt">
              <h2 v-html="$t('General.media')"></h2>
            </div>
            <span>{{ $t('General.brands') }}</span>
            <router-link :to="{ name: 'media' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 align-self-end">
          <div class="inner-prop height-500 b-wine-red">
            <div class="txt">
              <h2 v-html="$t('General.was-uns-ausmacht') "></h2>
            </div>
            <span>{{ $t('General.ueber-uns') }}</span>
            <router-link :to="{ name: 'was-uns-ausmacht' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-10 align-self-end">
          <div class="inner-prop height-400 b-violet">
            <div class="txt">
              <h2 v-html="$t('General.offene-positionen')"></h2>
            </div>
            <span>{{ $t('General.karriere') }}</span>
            <router-link :to="{ name: 'offene-positionen' }" class="stretched-link"></router-link>
          </div>
        </div>
      </div>
      <div class="row align-self-start justify-content-center">
        <div class="col-lg-4 col-md-6 col-10 align-self-start">
          <div class="inner-prop height-300 b-sand">
            <div class="txt">
              <h2 v-html="$t('General.management')"> </h2>
            </div>
            <span>{{ $t('General.ueber-uns') }}</span>
            <router-link :to="{ name: 'management' }" class="stretched-link"></router-link>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-10 align-self-start ">
          <div class="inner-prop height-400 b-blue-60per">
            <div class="txt">
              <h2 v-html="$t('General.styrianner-werden')"></h2>
            </div>
            <span>{{ $t('General.karriere') }}</span>
            <router-link :to="{ name: 'styrianerIn-werden' }" class="stretched-link"></router-link>
          </div>
        </div>
      </div>
    </div>
    <NewsBox />
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';

import SocialMediaIconBox from '../../components/Frontend/SocialMediaIconBox.vue';
import NewsBox from '../../components/Frontend/NewsBox.vue';
import Footer from '../../components/Frontend/Footer.vue';
import { mdiChevronUp } from '@mdi/js';

import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export type SearchRecord = {
  locale: string;
  title: string;
  meta: string;
  content: string;
  url: string;
  description: string;
  createdat: Date;
};

export default defineComponent({
  meta: {
    de: {
      title: 'Suche | Styria Media Group',
      keywords: ['suche','search','suchen nach','styria media group'],
      description: 'Wonach suchen Sie?',
      image: require('@/assets/img/og/startseite.png'),
      
    },
    en: {
      title: 'Search | Styria Media Group',
      keywords: ['search','graz','styria media group'],
      description: 'What are you looking for?',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    NewsBox,
    Footer,
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const filter = ref('');

    let timeout: number | undefined;

    const results = ref<Array<SearchRecord>>([]);
    const loading = ref(false);

    watch(filter, (newvalue, _) => {
      loading.value = true;
      if (newvalue == '') {
        clearTimeout(timeout);
        results.value = [];
        loading.value = false;
      } else {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          store.dispatch('search', { filter: filter.value, locale: i18n.locale.value }).then((res: any) => {
            results.value = res;
            loading.value = false;
          });
        }, 100);
      }
    });

    return {
      results,
      mdiChevronUp,
      filter,
      loading,
    };
  },
});
</script>
